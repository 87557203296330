.form-registro-container {
    height: auto;
}

#header-form-registro {
    padding: 12px;
    color: white;
    background-color: #7DDCFF;
    display: inline-block;
}

#imagen-en-formulario {
    margin-top: 32px;
}

/* select {
    margin: 12px;
} */

#addClub , #addLA{
    margin: 16px;
}

#addEspecialidad, #removeEspecialidad {
    margin: 8px
}

#registrarUsuario{
    margin-bottom: 80px;
}

.custom-select {
    max-height: 100px;
    overflow-y: auto;
}

.error-message-2 {
    color: red;
    /* margin-left: 12px; */
}