.pregunta-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.botones-container {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.botones-container button {
    border: none;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.botones-container button.si {
    border: 2px solid black;
    color: white;
    background-color: #4CAF50;
}

.botones-container button.no {
    border: 2px solid black;
    color: white;
    background-color: #f44336;
}

.botones-container button:focus,
.botones-container button:active {
    outline: none;
}

#continue {
    margin-top: 4px;
}

.pregunta {
    font-size: 2rem;
}