.pregunta-solicitud-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.botonSolicitud {
    border: 2px solid black;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12%;
    margin: 8px;
}

/* .info-solicitud{
    margin-top: 16px;
    margin-bottom: 16px;
} */

#aceptaSolicitud {
    color: white;
    background-color: #4CAF50;
}

#rechazaSolicitud {
    color: white;
    background-color: #f44336;
}

#decision-solicitud {
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: underline;
}

#btn-solicitud-medico-continuar {
    margin-top: 8px;
    /* margin-bottom: 24px; */
}

.info_sol {
    font-weight: 500;
}