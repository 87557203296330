.pie_pagina {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 999;  /* asegura que el footer esté detrás del contenido principal */
}

.logo_engtrade {
    max-width: 150px;
    max-height: 150px;
}

.texto_pie_pagina {
    text-align: center;
}