#boton-notif-cta {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 20px;
    left: 10px;
    z-index: 9999;
    padding: 0;
}

#icono-notificaciones-cta {
    font-size: 20px;
}

@media (max-width: 600px) {
    #boton-notificaciones-cta {
        right: 10px;
        top: 120px;
    }
}