.pregunta-turno-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-message {
    color: red;
    font-size: 14px;
}

.botonModalidad {
    border: 2px solid black;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12%;
    margin: 8px;
}

#presencial {
    color: white;
    background-color: #4CAF50;
}

#virtual {
    color: white;
    background-color: #f44336;
}

#texto-confirmacion-solicitud-turno {
    font-size: 16px;
    margin-top: 12px;
}

/* #boton-cambio {
    margin-top: 12px;
    margin-bottom: 20px;
} */

#boton-cambio {
    margin: 4px 4px 60px;
}

#boton-solicitar {
    margin-bottom: 60px;
}

@media (max-width: 470px) {
    /* Add your styles here for screens smaller than 480px */
    #boton-cambio {
            margin: 4px 4px 8px;
        }
}