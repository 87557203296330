/* #boton-cambiar {
    margin-top: 160px;
    margin-bottom: 20px;
} */

.info_asignacion {
    font-weight: 500;
    
}

#boton_asignacion {
    margin: 4px;
    z-index: 0;
}

#texto-confirmacion-asignacion-turno {
    font-size: 16px;
    margin-top: 12px;
}

/* #boton-asignar {
    margin-bottom: 60px;
} */