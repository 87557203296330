/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 300px;
}

.modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    z-index: 9;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #7DDCFF;
    border: 2px black solid;
}

#modal-close-icon {
    font-weight: 800;
}

#header-modal {
    background-color: #7DDCFF;
    color: black;
    font-weight: 600;
}

#description-modal {
    font-size: 18px;
    font-weight: 600;
}