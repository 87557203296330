.pregunta-oferta-container {
    margin-bottom: 50px;
}

.info-oferta {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
}

#lista-esp-of {
    margin: 0;
}