.lista-preg-frec-prof {
    width: 90%;
        margin: 0 auto;
        /* Esto centra la lista horizontalmente */
        padding: 0;
            /* Elimina el espacio interior predeterminado de la lista */
}

.item-lista-preg-frec-prof {
    margin-bottom: 10px;
    /* Añade espacio entre cada ítem de la lista */
}