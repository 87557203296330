.container-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 100vh; */
}
.btn-floating {
    width: 72px;
    height: 72px;
    font-size: 32px;
    line-height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.btn-floating i {
    font-size: 40px;
}
.center-text {
    text-align: center;
}
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 4px 0;
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 1.1rem;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.img-container{
    /* margin-top: -36px; */
    width: 100%
}
.img-container img {
    display: block;
    margin: 0 auto;
    max-width: 240px;
    max-height: 200px;
}
#selrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

#contenedor-img-registro {
    width: 100%
}

#leyenda-iniciar-sesion {
    position: relative;
    top: -100px;
}

#car {
    height: 100%;
    text-align: center;
}

.carrousel {
    margin: 20px;
    height: 400px;
    position: relative;
}

#car-item {
    display: inline-block;
    width: 100%;
    height: 100%;
}

#car-item img {
    max-width: 100%; 
    max-height: 100%; 
}

.carousel .carousel-item {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 400px) {
    .leyenda-icono {
        display: none;
    }
}