.error-404-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image:
            url("../../../images/rt.png"),
            /* Ruta de la primera imagen */
            url("../../../images/PTM.png");
            /* Ruta de la segunda imagen */
        background-position:
            top left,
            /* Posición de la primera imagen */
            bottom 72px right;
            /* Posición de la segunda imagen */
        background-repeat: no-repeat;
    background-size:
            auto,
            60%;
}

.error-404-content {
    position: relative;
    z-index: 1;
}

#error-404-header {
    color: rgb(19, 0, 128);
    font-weight: bold;
    text-align: center;
}

#error-404-subheader {
    color: rgb(32, 32, 32);
    font-weight: bold;
}

.error-404-text {
    font-weight: bold;
}

