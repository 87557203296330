.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.button-container button {
    margin-bottom: 5px;
    margin-left: 20px;
    /* Aumentar el margen izquierdo */
    margin-right: 20px;
    /* Aumentar el margen derecho */
    width: 40px;
        height: 40px;
        font-size: 32px;
        line-height: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
}

.button-container p {
    text-align: center;
}

.blue i, .black i {
    font-size: 20px;
}

.solicitarTurnop {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

#btn-close-sesion {
    margin-bottom: 80px;
}