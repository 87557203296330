#botonVolverConsultarTurno {
    margin-bottom: 40px;
}

#lastTextCT {
    margin-bottom: 40px;
}

.info_detalle {
    font-weight: 500;
    margin: 0
}